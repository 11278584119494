import { cdataDownloadFetch } from "../../../api/cdataFetch";

export function getDownloadQueryLogs(params: { queryId: string }) {
  const { queryId } = params;

  return cdataDownloadFetch({
    url: `/log/query/stream/${queryId}`,
    method: "GET",
    fileName: queryId,
    extension: ".zip",
  });
}
